

import KirbyLink from './kirbylink.vue'
export default
{
    name: "centeredtext",
    components: {
        KirbyLink
    },
    props: {
        content: Object
    },
    methods: {
        position_css($pos, $xpos){
            if($pos == "top"){
                return "lg:-top-8 -top-12 lg:" + $xpos + "40";
            }else if ($pos == "middle") {
                return "lg:top-1/2 lg:" + $xpos + "40";
            }
            else return "-bottom-8 lg:bottom-0 lg:" + $xpos + "40";
        },
    }
}

